
import React from 'react';
import moment from "moment";
import { TYPES } from '../../../utils/type';


const ProductSeeCard = ({data}) => {
  return (
    <div className='w-full'>
        <div className='text-white mt-2'>
            <div className='p-2 bg-[#2a2a79] rounded-md'>
                <h1 className='text-xs font-bold uppercase text-gray-300'>{data.note}</h1>
                {/* <CopyToClipboard text={data.trackcode}> */}
                    <div className='flex gap-2 items-center'>
                        <h1 className='text-sm font-bold uppercase mt-1'>{data.trackcode}</h1>
                        {/* <FaRegCopy onClick={CopyAlert}/> */}
                    </div>
                {/* </CopyToClipboard> */}
                <div className='mt-1 text-gray-200'>
                    <h1 className='md:text-md xs:text-xs'>Огноо</h1>
                    {
                        data.status === TYPES.REQUESTED ?
                        <h1 className='md:text-md xs:text-xs'>{moment(data.created_date).format('YYYY/MM/DD')}</h1>
                        :
                        <h1 className='md:text-md xs:text-xs'>{moment(data.date).format('YYYY/MM/DD HH:mm')}</h1>
                    }
                    <h1>Ачааны үнэ: {data.payment} ₮</h1>
                </div>
                <div className='flex justify-end'>
                    <div className='relative bg-[#9c812e] text-gray-100 p-1 xs:text-xs md:text-sm rounded-md mt-[-30px]'>
                        {
                            data.status === TYPES.REQUESTED &&
                            "Бүртгэсэн"
                        }
                        {
                            data.status === TYPES.IRSEN &&
                            "Ирсэн"
                        }
                        {
                            data.status === TYPES.CONFIRM &&
                            "Авсан"
                        }
                        {
                            data.status === TYPES.EREEN &&
                            "Эрээнд"
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProductSeeCard