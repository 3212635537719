import React, { useState } from 'react'
import SNav from '../../components/SNav';
import { Link, Outlet } from 'react-router-dom';
import MobileNav from '../../components/SNav/mobile';


const SHome = () => {
  return (
    <div className='h-screen'>
      {/* <div className='fixed h-screen w-full bg-[#141159] opacity-90 z-40'>
        <h1 className='text-white z-50 text-center mt-80 text-2xl'>Системийн шинэчлэл хийгдэж байна. Түр хүлээнэ үү</h1>
      </div> */}
      <div className='flex justify-center min-h-full font-Roboto'>
        <div className='xs:w-full md:w-[700px] bg-gray-200 shadow-md flex justify-between flex-col'>
            <div className='py-2 px-8 pb-24'>
              <div className='flex mt-2 justify-between'>
                  <div className='w-20'>
                      <MobileNav/>
                  </div>
                  <Link to="/">
                  <img src='../../logo.jpg' className='xs:h-10 md:h-24 rounded-full'/></Link>
                  <h1 className='w-20'></h1>
              </div>
              <Outlet/>
            </div>
            <SNav/>
        </div>
      </div>
    </div>
  )
}

export default SHome;
